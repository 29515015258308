import { Injectable, WritableSignal, signal } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DenseService {
    _displayHeaderAndNav: WritableSignal<boolean> = signal(true);
    _displayProjectFilter: WritableSignal<boolean> = signal(false);
    constructor() {}

    set displayHeaderAndNav(value: boolean) {
        this._displayHeaderAndNav.set(value);
    }

    get displayHeaderAndNav() {
        return this._displayHeaderAndNav();
    }

    set displayProjectFilter(value: boolean) {
        this._displayProjectFilter.set(value);
    }

    get displayProjectFilter() {
        return this._displayProjectFilter();
    }
}
